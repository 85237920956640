import React, { useState } from "react";
import "./leagues.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRankingStar,
  faCalendarDays,
  faSignalPerfect,
  faMedal,
  faBasketball,
  faTrophy,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { getTournamentWeek, weeksBetweenDates } from "../../utils/utils";
import { useGetPOTW } from "../../hooks/useGetPOTW";
import "../Results/results.css";
import "../../App.css";
import { useGetGamesByWeek } from "../../hooks/useGetGamesByWeek";
import { useGetGameResultsByWeek } from "../../hooks/useGetGameResultsByWeek";
import Modal from "../../components/Modal";
import Logo from '../../images/logo.svg';
import BigInfoPanel from "../../components/BigInfoPanel";
import getImage from '../../hooks/useGetPOTWImage'

export default () => {
  //let thisWeek = weeksBetweenDates(new Date(2023, 0, 15), new Date(), 0);
  let thisWeek = getTournamentWeek("2025-02-15")
  if(thisWeek > 11){
    thisWeek = 11
  }
  const [checked, setChecked] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(thisWeek);
  const [potw, setPotw] = useGetPOTW(
    "d1-sunday-league",
    currentWeek.toString()
  );

  const [games] = useGetGamesByWeek(currentWeek.toString(), "d1-sunday-league");
  const [gameResults] = useGetGameResultsByWeek(currentWeek.toString());
  const [currentGame, setCurrentGame] = useState(0)
  const [showModal, setShowModal] = useState(false)
  potw.sort((a,b) => a.value['Week'] - b.value['Week'])
  function onChange() {
    setChecked(!checked);
  }

  function changeWeek(increment) {
    if (increment === "prev" && currentWeek > 1) {
      let week = 1;
      if (currentWeek != 1) {
        week = currentWeek - 1;
      }
      setCurrentWeek(week);
    }
    //make this go up to number of weeks in db?
    if (increment === "next" && currentWeek < 11) {
      let week = currentWeek + 1;
      setCurrentWeek(week);
    }
  }
  function changeGame(increment) {
    if (increment === "prev") {
      let index = currentGame - 1
      if(index === -1){
        index = games.length - 1
      }
      setCurrentGame(index);
    }
    if (increment === "next") {
      let index = currentGame + 1
      if(index === games.length){
        index = 0
      }
      setCurrentGame(index);
    }
  }

  function openModal(){
    setShowModal(!showModal)
    if(showModal){
      document.body.style.overflow = 'auto'
    }else{
      document.body.style.overflow = 'hidden'
    }
  }

  return (
    <div className="leagues background1">
      <div className="page">
        <div className="d1">
          <div className="toggle--container">
            <div>D1 Sunday League</div>
            <label className="switch">
              <input type="checkbox" checked={checked} onChange={onChange} />
              <span className="slider make-round"></span>
            </label>
            <div>League Information</div>
          </div>
          {!checked && (
            <>
              {/* {potw.length > 0 && potw.length >= currentWeek && ( */}
                <div className="potw--container">
                  <div className="potw--header">
                    <h3>Player of the Week</h3>
                    {potw.length > 0 && potw.length >= currentWeek && (
                    <h3>
                      {potw[currentWeek - 1].value.FirstName}{" "}
                      {potw[currentWeek - 1].value.LastName}
                    </h3>
                    )}
                    {potw.length < currentWeek && (
                      <h3>TBD</h3>
                    )}
                  </div>
                  <div className="potw--image-container">
                    <div>
                      <img
                        src={potw.length > 0 && potw.length >= currentWeek ? "https://themeccahoops-potw.s3.us-east-1.amazonaws.com/d1-sunday-league-"+currentWeek+"-potw.jpeg" : Logo}
                        className="potw--image"
                        alt="potw"
                      />
                    </div>
                  </div>
                  <div className="potw--text">
                  {potw.length > 0 && potw.length >= currentWeek && (
<>                  {potw[currentWeek - 1].value.Score}</>
  
                    )}

                  </div>
                </div>
              {/* )} */}
              {/* <ResultsView league="d1-sunday-league" week={currentWeek} /> */}
              <div className="week-list">
                <span onClick={() => changeWeek("prev")}>
                  <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                </span>
                <div>Week {currentWeek}</div>
                <span onClick={() => changeWeek("next")}>
                  <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                </span>
              </div>
              <ul className="icon-menu">
                <li className="menu-item">
                  <a href="/standings/d1-sunday-league">
                    <FontAwesomeIcon icon={faRankingStar} />
                    Teams & Standings
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/results/d1-sunday-league">
                    <FontAwesomeIcon icon={faCalendarDays} />
                    Schedule & Results
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/d1-sunday-league/stats">
                    <FontAwesomeIcon icon={faSignalPerfect} />
                    Season Stat Leaders
                  </a>
                </li>
              </ul>
              <ul className="icon-menu">
                <li className="menu-item">
                  <a href="/d1-sunday-league/records">
                    <FontAwesomeIcon icon={faMedal} />
                    All Time Records
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/d1-sunday-league/all-time-stat-leaders">
                    <FontAwesomeIcon icon={faBasketball} />
                    All Time Stat Leaders
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/d1-sunday-league/spring-2023/playoff-bracket">
                    <FontAwesomeIcon icon={faTrophy} />
                    Playoff Bracket
                  </a>
                </li>
              </ul>
              <a href="/d1-sunday-league/free-agent-list"><div class="btn-yellow">Free Agent List</div></a>
            </>
          )}
          {checked && (
            <>
              <h2>League Information</h2>
              <BigInfoPanel header={'DIVISION 1'}>
              <div className="league-info--content">
                  Division 1 is the highest level of competition in The Mecca League.<br></br><br></br>
                  This consists of pro, ex pro, overseas, and college
                  athletes.
                </div>
              </BigInfoPanel>
              <BigInfoPanel header={'D1 LEAGUE FORMAT'}>
                12 Team League Top 8 Make Playoffs<br></br>
                <br></br>
                11 Game Season + Playoffs<br></br>
                <br></br>
                Player stats<br></br>
                <br></br>
                Three Refs + Pro Rules<br></br>
                <br></br>
                Four 12 minute quarters<br></br>
                <br></br>
                Shot Clock + Running Game Clock<br></br>
                (Clock stops in last 2 mins if score is within 10 points)<br></br>
                <br></br>
                League winners get next season free and Championship rings
              </BigInfoPanel>
              <BigInfoPanel header={'LOCATION/TIME/FEES'}>
              <p>
                D1 Sunday Winter League will start on January 15th, 2023 –
                May 7th, 2023.
              </p>
              <p>
                Calvary Costa Mesa 3800 S Fairview St Santa Ana, CA 92704
              </p>
              <p>GAME TIMES: 2PM – 8PM</p>
              <p>
                TEAM FEE: $950 Per Team <br></br>
                <br></br>
                (Ref fees not included) <br></br>
                REF FEE: $70 Per Game<br></br>
              </p>
              </BigInfoPanel>
              <br></br>
              <h2>Registration Information</h2>
              <ul className="main-menu registration">
                <li className="menu-item">
                  <a href="/d1-sunday-league-registration">
                    D1 Sunday League Registration
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/d1-sunday-league-waitlist-registration">
                    D1 Sunday League Waitlist Registration
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/d1-sunday-league-free-agent-registration">
                    D1 Sunday League Free Agent Registration
                  </a>
                </li>
              </ul>
              <br></br>
              <h2>League Rules</h2>
              <input
                class="league-rules--input"
                type="radio"
                name="d1-sunday-league"
                id="rule1"
              />
              <label for="rule1">
                <div className="league-rules--container">
                  <div className="league-rules--box">
                    <h2 className="league-rules--title">JERSEYS</h2>
                    <div className="league-rules--content">
                    Players are required to wear numbered reversible jerseys or have home and away jerseys.
                    <br></br>
                    <br></br>
                    Players without a number or players wearing the same number as a teammate will receive a team technical foul.
                    </div>
                  </div>
                </div>
              </label>

              <input
                class="league-rules--input"
                type="radio"
                name="d1-sunday-league"
                id="rule2"
              />
              <label for="rule2">
                <div className="league-rules--container">
                  <div className="league-rules--box">
                    <h2 className="league-rules--title">ROSTERS</h2>
                    <div className="league-rules--content">
                    Teams can have a maximum of 15 players on their roster.<br></br><br></br>
                    Captains can make a maximum of 3 roster moves throughout the season through the captain portal.<br></br><br></br>
                    Adding a player to the roster will count as 1 roster move. <br></br><br></br>
                    Dropping players from the roster will not count as a roster move.<br></br><br></br>
                    Free agent additions will not count as roster move.<br></br><br></br>
                    Rosters lock after the first regular season game is played.
                    </div>
                  </div>
                </div>
              </label>
              <input
                class="league-rules--input"
                type="radio"
                name="d1-sunday-league"
                id="rule3"
              />
              <label for="rule3">
                <div className="league-rules--container">
                  <div className="league-rules--box">
                    <h2 className="league-rules--title">ELIGIBILITY</h2>
                    <div className="league-rules--content">
                      To participate in The Mecca League you must be 18 years of
                      age or older.
                    </div>
                  </div>
                </div>
              </label>
              <input
                class="league-rules--input"
                type="radio"
                name="d1-sunday-league"
                id="rule4"
              />
              <label for="rule4">
                <div className="league-rules--container">
                  <div className="league-rules--box">
                    <h2 className="league-rules--title">FORFEIT POLICY</h2>
                    <div className="league-rules--content">
                      A team must play with a minimum of 5 players or the game
                      will result in a forfeit.<br></br><br></br>
                      Teams must have five or more players ready to start at
                      game time or the opposing team will receive a point for
                      every minute past the start time.<br></br><br></br>
                      If a team is not ready by game time it will result in a $35
                      fine that will go towards the opposing teams referee fee.<br></br><br></br>
                      If a team is not ready by the end of the first quarter, it
                      will result in a forfeit.<br></br><br></br>
                      If a team forfeits, that team will be charged $140 to
                      cover ref fees and scorekeepers reserved for that game.
                      <br></br><br></br>
                      All fines must be paid prior to the start of the next game
                      or your team will have to forfeit every week until that
                      fine is paid.
                    </div>
                  </div>
                </div>
              </label>
              <input
                class="league-rules--input"
                type="radio"
                name="d1-sunday-league"
                id="rule5"
              />
              <label for="rule5">
                <div className="league-rules--container">
                  <div className="league-rules--box">
                    <h2 className="league-rules--title">SUBSTITUTIONS</h2>
                    <div className="league-rules--content">
                    If a team is in need of additional players to avoid a forfeit, 
                    the team can substitute players outside of their team roster with 
                    the opposing team captain's permission.
                    <br></br><br></br>
                    Each substitution that has a different color jersey will receive a technical foul.
                    <br></br><br></br>
                    If a captain agrees to let the other team substitute players outside of their roster, 
                    the winner of that game will count on the official record.
                    <br></br><br></br>
                    There will be a $20 dollar fine for each sub outside of a teams roster.
                    </div>
                  </div>
                </div>
              </label>
              <br></br>
                  <div class="btn-yellow" onClick={openModal}>PLAYER AGREEMENT</div>
              {showModal && <Modal openModal={openModal}>
              <p>PLAYER INJURY WAIVER</p>
<p>In consideration of being allowed to participate in any way in (THE MECCA LEAGUE) athletic sports program, related events and activities, i acknowledge, appreciate, and agree that:<br></br>
		The risks of injury and illness (ex: communicable diseases such as MRSA, influenza, and COVID-19) from the activities involved in this program are significant, including the potential for permanent paralysis and death, and while particular rules, equipment, and personal discipline may reduce these risks, the risks of serious injury and illness do exist;<br></br> and,
		I KNOWINGLY AND FREELY ASSUME ALL SUCH RISKS, both known and unknown, EVEN IF ARISING FROM THE NEGLIGENCE OF THE RELEASEES or others, and assume full responsibility for my participation;<br></br> and,
		I willingly agree to comply with the stated and customary terms and conditions for participation.<br></br> If, however, I observe any unusual significant hazard during my presence or participation, I will remove myself from participation and bring such to the attention of the nearest official immediately;<br></br> and,
		I, for myself and on behalf of my heirs, assigns, personal representatives and next of kin, HEREBY RELEASE AND HOLD HARMLESS (THE MECCA LEAGUE) their officers, officials, agents, and/or employees, other participants, sponsoring agencies, sponsors, advertisers, and if applicable, owners and lessors of premises used to conduct the event (“RELEASEES”), WITH RESPECT TO ANY AND ALL INJURY, ILLNESS, DISABILITY, DEATH, or loss or damage to person or property, WHETHER ARISING FROM THE NEGLIGENCE OF THE RELEASEES OR OTHERWISE, to the fullest extent permitted by law.</p>


<p>PHOTO/VIDEO RELEASE</p>

<p>I hereby grant The Mecca League permission to the rights of my image, likeness and sound of my voice as recorded on audio or video tape without payment or any other consideration. <br></br>I understand that my image may be edited, copied, exhibited, published or distributed and waive the right to inspect or approve the finished product wherein my likeness appears.<br></br> Additionally, I waive any right to royalties or other compensation arising or related to the use of my image or recording.<br></br> I also understand that this material may be used in advertisements, displays, pamphlets/brochures, and on league website.</p>


<p>PLAYER CONDUCT</p>

<p>
-All player are to conduct themselves in a appropriate and sportsmanlike manner<br></br>
-No player has the right to physically or verbally abuse officials, scorekeepers, or other players<br></br>
-The captain is responsible for the action of his teammates. Should anyone on the team get in a fight, that player will be banned for the rest of the season.
</p>

<p>REFUND POLICY</p>

<p>All refund requests must be submitted in writing to the League Commissioner.
Security deposits are non-refundable. No refunds will be granted after the first team game has been played.</p>
                </Modal>}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
