import React from "react";
import "./results.css";
import "../../App.css";
import { useGetTeams } from "../../hooks/useGetTeams";

const EventBoxMinimal = (data) => {
  //console.log('_log teams: ', data)
  const homeTeam = data.teams.find(t => t.id === data.game.value["HOME"])
  const awayTeam = data.teams.find(t => t.id === data.game.value["AWAY"])
  return (
    <div className="eventbox minimal" key={data.game.id}>
      <a key={data.game.id} href={`/game-results/${data.league}/${data.game.id}`}>
        <div className="eventbox--details results">
          <div className="eventbox--details-teams">
            <div className="details-teams-column team">
              {/* <div className="homeaway">HOME</div> */}
              <div>{data.game.value["HOME"]} </div>
              {/* <div>{homeTeam.value["Wins"]}|{homeTeam.value["Losses"]}</div> */}
{/* JOSH NOTE: if score show else show standing */}
            </div>
            {data.hasResults && <div className="details-teams-column score">
            <div className="details-teams-results">{data.gameResults.value["Team1Score"]}</div>
            </div>}
            {/* <span>
                {data.gameResults.value["Team1Score"]}/
                {data.gameResults.value["Team2Score"]}
              </span> */}
            <div className="details-teams-column">
              <div className="eventbox--details-datetime">
                {!data.hasResults && <>
                <div>{data.game.value["Date"]}</div>
                <div>{data.game.value["Time"]}</div>
                </>
                }
                {data.hasResults && <>
                  <div>FINAL</div>
                </>}
              </div>
            </div>
            {data.hasResults && <div className="details-teams-column score">
            <div className="details-teams-results">{data.gameResults.value["Team2Score"]}</div>
            </div>}
            <div className="details-teams-column team">
              {/* <div className="homeaway">AWAY</div> */}
              <div>{data.game.value["AWAY"]}</div>
              {/* <div>{awayTeam.value["Wins"]}|{awayTeam.value["Losses"]}</div> */}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default EventBoxMinimal;
